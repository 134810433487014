import React, { Suspense, lazy, useEffect } from "react"
import "./assets/styles/globals.scss"
import "./assets/styles/select.scss"
import "./assets/styles/datepicker.scss"
import "./assets/styles/odometer.scss"
import "./assets/styles/lantern.scss"
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"
import { Loading } from "./components/common"
import { defaultMaterialTheme } from "./assets/theme"
import { AdProspectType } from "./mobxStores/OrderStore"
import { QueryClient, QueryClientProvider } from "react-query"
import Login from "components/pages/Login"
import { StyledThemeContextProvider } from "context/styledThemeContextProvider"
import { LocaleContextProvider } from "context/intlContext"
import { Toaster } from "react-hot-toast"
import { useLoadScript } from "@react-google-maps/api"
import IntegrationAuth from "components/routes/IntegrationAuth"
import OrderAuth from "components/routes/OrderAuth"
import { AuthProvider, RequireAuth } from "react-auth-kit"
import refreshApi from "api/refreshApi"
import { paths } from "utils/paths"
import NoFacebookAdPage from "components/pages/NoAd"
import PreviewUrl from "components/pages/NoAd/PreviewUrl"

const ClientList = lazy(() => import("components/pages/ClientList"))
const GroupList = lazy(() => import("components/pages/GroupList"))
const CampaignList = lazy(() => import("components/pages/CampaignList"))
const ClientDashboard = lazy(() => import("components/pages/ClientDashboard"))
const DataSourceNew = lazy(() => import("components/pages/DataSourceNew"))
const DataSourceEdit = lazy(() => import("components/pages/DataSourceEdit"))
const StaticDataSourceNew = lazy(() => import("components/pages/StaticDataSourceNew"))
const StaticDataSourceEdit = lazy(() => import("components/pages/StaticDataSourceEdit"))
const TemplateAdNew = lazy(() => import("components/pages/TemplateAd/TemplateAdNew"))
const TemplateAdEdit = lazy(() => import("components/pages/TemplateAd/TemplateAdEdit"))
const ProspectingNew = lazy(() => import("components/pages/ProspectingNew"))
const ProspectingEdit = lazy(() => import("components/pages/ProspectingEdit"))
const ProspectingShow = lazy(() => import("components/pages/ProspectingShow"))
const Register = lazy(() => import("components/pages/Register"))
const NotFound = lazy(() => import("components/pages/NotFound"))
const Unauthorized = lazy(() => import("components/pages/Unauthorized"))
const PermissionDenied = lazy(() => import("components/pages/PermissionDenied"))
const Reports = lazy(() => import("components/pages/Report"))
const IframePreview = lazy(() => import("components/pages/IframePreview"))
const Order = lazy(() => import("components/pages/Order"))
const OrderList = lazy(() => import("components/pages/OrderList"))
const UserList = lazy(() => import("components/pages/UserList"))
const VideoTemplateList = lazy(() => import("components/pages/VideoTemplateList"))
const IntegrationDraft = lazy(() => import("components/pages/IntegrationDraft"))
const ResellerList = lazy(() => import("components/pages/ResellerList"))
const TenantList = lazy(() => import("components/pages/TenantList"))
const TenantDashboard = lazy(() => import("components/pages/TenantDashboard"))
const UserDashboard = lazy(() => import("components/pages/UserDashboard"))
const DisplayFormatTest = lazy(() => import("components/pages/DisplayFormatTest"))
const DataSourceFailList = lazy(() => import("components/pages/DataSourceFailList"))
const Handover = lazy(() => import("components/pages/Handover"))
const UserDraftList = lazy(() => import("components/pages/UserDraftList"))
const Telemetry = lazy(() => import("components/pages/Telemetry"))
const PdfUpload = lazy(() => import("components/pages/PdfUpload"))
const ProfilesList = lazy(() => import("components/pages/ProfilesList"))
const CreateCampaignForm = lazy(() => import("components/forms/CreateCampaignForm"))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600 * 1000,
    },
  },
})
const LIBRARIES: ("geometry" | "drawing" | "places")[] = ["geometry", "drawing", "places"]
const toastOptions = { error: { duration: 5000 }, success: { duration: 4000 } }

const App = () => {
  useEffect(() => {
    document.title = window.tenant.product.name
  }, [])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBbEAMAaQEqfduL1IAE9TuPJYJ-KWaRt34",
    libraries: LIBRARIES,
  })
  if (!isLoaded) {
    return null
  }
  return (
    <AuthProvider authType={"localstorage"} authName={"token"} refresh={refreshApi}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <LocaleContextProvider>
            <StyledThemeContextProvider>
              <ThemeProvider theme={defaultMaterialTheme}>
                <Suspense fallback={<Loading />}>
                  <React.Fragment>
                    <Toaster toastOptions={toastOptions} />
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ClientList />
                          </RequireAuth>
                        }
                      />
                      <Route path={paths.authentication.login} element={<Login />} />

                      <Route
                        path="/campaigns"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <CampaignList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/tenants/:tenantId/pdfupload"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <PdfUpload />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/groups"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <GroupList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/orders"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <OrderList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/users"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <UserList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/VideoTemplates"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <VideoTemplateList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/resellers"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ResellerList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/tenants"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <TenantList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/data_source_failures"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <DataSourceFailList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/profiles"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ProfilesList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/create-campaign"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <CreateCampaignForm />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/userDrafts"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <UserDraftList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/tenant/:id"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <TenantDashboard />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/user/:id"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <UserDashboard />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="/display_format_test/:width/:height/:templateId/:dataSourceId"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <DisplayFormatTest />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="/:slug"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ClientDashboard />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/data_sources/new"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <DataSourceNew />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/data_sources/:id/edit"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <DataSourceEdit />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/static_data_sources/new"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <StaticDataSourceNew />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/static_data_sources/:id/edit"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <StaticDataSourceEdit />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/templates/new"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <TemplateAdNew />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/templates/:id/edit"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <TemplateAdEdit />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="/:slug/prospecting/new"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ProspectingNew />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/prospecting/:id"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ProspectingShow />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/prospecting/:id/edit"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ProspectingEdit />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/prospecting/:id"
                        element={
                          <RequireAuth loginPath={paths.authentication.login}>
                            <ProspectingShow />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/:slug/draft/:draftId"
                        element={
                          <OrderAuth>
                            <IntegrationDraft />
                          </OrderAuth>
                        }
                      />
                      <Route
                        path="/:slug/integration/:draftId"
                        element={
                          <IntegrationAuth>
                            <IntegrationDraft />
                          </IntegrationAuth>
                        }
                      />

                      <Route path="/register/:id/:linkType/:orderType?" element={<Register />} />

                      <Route path="/reports/:id" element={<Reports />} />

                      <Route path="/campaign/:id/preview/:channel" element={<PreviewUrl />} />

                      <Route
                        path="/:slug/prospect/:prospectId"
                        element={
                          <OrderAuth>
                            <Order adProspectType={AdProspectType.newProspect} />
                          </OrderAuth>
                        }
                      />

                      <Route
                        path="/:slug/prospect/:prospectId/edit"
                        element={
                          <OrderAuth>
                            <Order adProspectType={AdProspectType.editProspect} />
                          </OrderAuth>
                        }
                      />

                      <Route
                        path="/:slug/prospect/:prospectId/extend"
                        element={
                          <OrderAuth>
                            <Order adProspectType={AdProspectType.extendProspect} />
                          </OrderAuth>
                        }
                      />

                      <Route path="/preview/:previewId/iframe" element={<IframePreview />} />

                      <Route path="/order/hr" element={<Order adProspectType={AdProspectType.humanResourcesProspectDemo} />} />

                      <Route path="/order/re" element={<Order adProspectType={AdProspectType.realEstateProspectDemo} />} />

                      <Route path="/order/car" element={<Order adProspectType={AdProspectType.carProspectDemo} />} />

                      <Route path="/order/nl" element={<Order adProspectType={AdProspectType.dutchDemo} />} />

                      <Route path="/order/fi" element={<Order adProspectType={AdProspectType.finnishDemo} />} />

                      <Route path="/order/demo" element={<Navigate to="/order/re" />} />

                      <Route path="/handover" element={<Handover />} />

                      <Route path="/telemetry" element={<Telemetry />} />

                      <Route path="/401" element={<Unauthorized />} />
                      <Route path="/403" element={<PermissionDenied />} />
                      <Route path="/no_ad" element={<NoFacebookAdPage />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </React.Fragment>
                </Suspense>
              </ThemeProvider>
            </StyledThemeContextProvider>
          </LocaleContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </AuthProvider>
  )
}
export default App