export enum ScheduledReportType {
  Weekly = "weekly",
  Monthly = "monthly",
  Quaterly = "quaterly",
  Yearly = "yearly",
}

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Success = "Success",
  Dark = "Dark",
  Delete = "Delete",
  Transparent = "Transparent",
  Themed = "Themed",
  Icon = "Icon",
  Danger = "Danger",
  Custom = "Custom",
}

export enum LinkType {
  Edit = "edit",
  Order = "order",
}

export enum OrderType {
  Prospect = "prospect",
  Draft = "draft",
}

export enum SnackbarType {
  Success = "Success",
  Error = "Error",
}

export enum CallToActionType {
  NoButton = "NO_BUTTON",
  LearnMore = "LEARN_MORE",
  BuyNow = "BUY_NOW",
  ApplyNow = "APPLY_NOW",
  BookTravel = "BOOK_TRAVEL",
  WatchMore = "WATCH_MORE",
  ContactUs = "CONTACT_US",
  Download = "DOWNLOAD",
  GetOffer = "GET_OFFER",
  GetQuote = "GET_QUOTE",
  SignUp = "SIGN_UP",
  Subscribe = "SUBSCRIBE",
  GetShowtimes = "GET_SHOWTIMES",
  RequestTime = "REQUEST_TIME",
  CallNow = "CALL_NOW",
}

export enum PinMeToCallToActionType {
  None = "None",
  LearnMore = "LearnMore",
  Shop = "Shop",
  SignUp = "SignUp",
  Book = "Book",
  Call = "Call", 
}

export enum BackgroundSizeType {
  Auto = "auto",
  Cover = "cover",
  Contain = "contain",
}

export enum Role {
  User = "user",
  Admin = "admin",
  ProspectUser = "prospectUser",
  TenantManager = "tenantManager",
  ApiKeyUser = "apiKeyUser",
}

export enum ApiErrorType {
  notFound = "notFound",
  failed = "failed",
  unauthorized = "unauthorized",
}

export enum ImageTextOption {
  Same = "same",
  Unique = "unique",
}

export enum OverrideImageText {
  Descriptions = "overrideImageDescriptions",
  Titles = "overrideImageTitles",
  PhoneNumbers = "overrideImagePhoneNumbers",
}

export enum PackageType {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum CampaignStateType {
  Archived = "archived",
  Paused = "paused",
  Ended = "ended",
  Scheduled = "scheduled",
  Live = "live",
}

export enum ChannelCampaignState {
  Scheduled = "scheduled",
  Ended = "ended",
  Active = "active",
  Warning = "warning",
  Broken = "broken",
}

export enum AdChannelType {
  Facebook = "facebook",
  BidTheatre = "bidTheatre",
  YouTube = "youTube",
  YouTubeGoogleAds = "youTubeGoogleAds",
  LinkedIn = "linkedIn",
}

export enum BudgetAdChannelType {
  Facebook = "facebook",
  BidTheatre = "bidTheatre",
  LinkedIn = "linkedIn",
  YouTube = "youTube",
}

export enum TemplateChannel {
  Facebook = "facebook",
  BidTheatre = "bidTheatre",
  PinMeTo = "pinMeTo",
}

export enum OrderTemplateChannel {
  Facebook = "facebook",
  BidTheatre = "bidTheatre",
  LinkedIn = "linkedIn",
}

export enum SocialFormats {
  CarouselAd = "carousel",
  CallAd = "call",
  SingleAd = "single",
}

export enum FilterType {
  Contains = "contains",
  NotContains = "notContains",
  LessThan = "lessThan",
  GreaterThan = "greaterThan",
  HasValue = "hasValue",
  NoValue = "noValue",
  Regex = "regex",
  RegexInverted = "regexInverted",
  Equals = "equals",
  In = "in",
  NotIn = "notIn",
}

export enum FilterLogic {
  OR = "or",
  AND = "and",
  OR_NOT = "or_not",
  AND_NOT = "and_not",
}

export enum ProspectType {
  Job = "job",
  RealEstate = "realEstate",
  Offer = "offer",
  Object = "object",
}

export enum CanUploadSetting {
  No = "no",
  ToProspect = "toProspect",
  ToClient = "toClient",
  ToHQ = "toHQ",
}

export enum LandingPageSetting {
  Uneditable = "uneditable",
  Unique = "unique",
  Same = "same",
}

export enum PhoneNumberSetting {
  Disabled = "disabled",
  Unique = "unique",
  Same = "same",
}

export enum Gender {
  Unspecified = "unspecified",
  Men = "men",
  Women = "women",
}

export enum ReferenceType {
  None = "none",
  Optional = "optional",
  Required = "required",
  Locked = "locked",
}

export enum WhitespaceType {
  Normal = "normal",
  NoWrap = "nowrap",
  Pre = "pre",
  PreWrap = "pre-wrap",
  PreLine = "pre-line",
  BreakSpaces = "break-spaces",
}

export enum TextTransformType {
  Capitalize = "capitalize",
  Lowercase = "lowercase",
  Uppercase = "uppercase",
  None = "none",
}

export enum DataSourceProviderTemplateFilter {
  Combine = "combine",
  Reset = "reset",
}

export enum DataSourceProviderFieldItemType {
  Text = "text",
  Image = "image",
  Bool = "bool",
  Follow = "follow",
  Array = "array",
  Json = "json",
  RootValue = "rootValue",
  Object = "object",
}

export enum BorderStyleType {
  None = "none",
  Solid = "solid",
  Dotted = "dotted",
  Dashed = "dashed",
  Double = "double",
  Groove = "groove",
  Ridge = "ridge",
  Inset = "inset",
  Outset = "outset",
}

export enum FontWeightType {
  Normal = "normal",
  Bold = "bold",
  Bolder = "bolder",
  Lighter = "lighter",
}

export enum TextAlignType {
  Start = "start",
  Center = "center",
  End = "end",
}

export enum ImagePositionType {
  Top = "top",
  Center = "center",
  Bottom = "bottom",
}

export enum ButtonPositionType {
  Start = "start",
  Center = "center",
  End = "end",
  Stretch = "stretch",
  Unset = "unset",
}

export enum DataSourceProviderSortingOrder {
  Ascending = "ascending",
  Descending = "descending",
}

export enum Vatriant {
  Exclude = "exclude",
  Include = "include",
}

export enum RecipientMapTarget {
  Email = "email",
  Name = "name",
}

export enum FacebookPageAccess {
  None = "none",
  Pending = "pending",
  Approved = "approved",
  Unknown = "unknown",
}

export enum VideoFormats {
  None = "none",
  Square = "square",
  Portrait9x16 = "portrait9x16",
  Landscape16x9 = "landscape16x9",
  Vertical = "vertical",
}

export enum AdPlacements {
  Post,
  Story,
}

export enum VideoGroupings {
  Shared = "shared",
  Specific = "specific",
  Carousel = "carousel",
}

export const AdsLastUpdated = [
  { value: 1, label: "oneDay" },
  { value: 2, label: "twoDays" },
  { value: 3, label: "threeDays" },
  { value: 4, label: "fourDays" },
  { value: 5, label: "fiveDays" },
  { value: 6, label: "sixDays" },
  { value: 7, label: "oneWeek" },
  { value: 14, label: "twoWeeks" },
  { value: 21, label: "threeWeeks" },
]

export enum copyTemplatePage {
  SelectDataSource = 0,
  SelectTemplate = 1,
}

export enum selectTab {
  SelectTemplateTab = 0,
  CopyTemplateTab = 1,
}

export enum DraftStatus {
  NotOpened = "notOpened",
  Opened = "opened",
  Ordered = "ordered",
}

export enum CrawlDelays {
  "00:15:00" = "00:15:00",
  "00:30:00" = "00:30:00",
  "00:45:00" = "00:45:00",
  "01:00:00" = "01:00:00",
  "03:00:00" = "03:00:00",
  "06:00:00" = "06:00:00",
  "12:00:00" = "12:00:00",
  "1.00:00:00" = "1.00:00:00",
  "3.00:00:00" = "3.00:00:00",
  "7.00:00:00" = "7.00:00:00",
}

export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum CustomCrawlDelays {
  "Every Monday At 9 AM" = "00 09 * * 1",
  "Every Monday At 10 AM" = "00 10 * * 1",
}

export enum CrawlOptions {
  crawlDelay,
  customInterval,
  crownExpression,
}

export enum OrderCancelReason {
  Duplicate = "duplicate",
  Regretted = "regretted",
  Incorrect = "incorrect",
}

export enum EndDateSetting {
  None = "none",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export enum AvailableCategoriesType {
  "IAB1" = 1,
  "IAB10",
  "IAB10-1",
  "IAB10-2",
  "IAB10-3",
  "IAB10-4",
  "IAB10-5",
  "IAB10-6",
  "IAB10-7",
  "IAB10-8",
  "IAB10-9",
  "IAB11",
  "IAB1-1",
  "IAB11-2",
  "IAB11-4",
  "IAB12",
  "IAB1-2",
  "IAB12-1",
  "IAB12-2",
  "IAB12-3",
  "IAB13",
  "IAB1-3",
  "IAB13-1",
  "IAB13-10",
  "IAB13-11",
  "IAB13-12",
  "IAB13-2",
  "IAB13-3",
  "IAB13-4",
  "IAB13-5",
  "IAB13-6",
  "IAB13-7",
  "IAB13-8",
  "IAB13-9",
  "IAB14",
  "IAB1-4",
  "IAB14-1",
  "IAB14-2",
  "IAB14-4",
  "IAB14-5",
  "IAB14-6",
  "IAB14-7",
  "IAB15",
  "IAB1-5",
  "IAB15-10",
  "IAB15-2",
  "IAB15-3",
  "IAB15-4",
  "IAB15-6",
  "IAB15-7",
  "IAB15-8",
  "IAB15-9",
  "IAB16",
  "IAB1-6",
  "IAB16-1",
  "IAB16-2",
  "IAB16-3",
  "IAB16-4",
  "IAB16-5",
  "IAB16-6",
  "IAB16-7",
  "IAB17",
  "IAB1-7",
  "IAB17-1",
  "IAB17-10",
  "IAB17-11",
  "IAB17-12",
  "IAB17-13",
  "IAB17-14",
  "IAB17-15",
  "IAB17-16",
  "IAB17-17",
  "IAB17-18",
  "IAB17-19",
  "IAB17-2",
  "IAB17-20",
  "IAB17-21",
  "IAB17-23",
  "IAB17-24",
  "IAB17-25",
  "IAB17-26",
  "IAB17-27",
  "IAB17-28",
  "IAB17-29",
  "IAB17-3",
  "IAB17-30",
  "IAB17-31",
  "IAB17-32",
  "IAB17-33",
  "IAB17-34",
  "IAB17-35",
  "IAB17-36",
  "IAB17-37",
  "IAB17-38",
  "IAB17-39",
  "IAB17-4",
  "IAB17-40",
  "IAB17-41",
  "IAB17-42",
  "IAB17-43",
  "IAB17-44",
  "IAB17-5",
  "IAB17-6",
  "IAB17-7",
  "IAB17-8",
  "IAB17-9",
  "IAB18",
  "IAB18-1",
  "IAB18-2",
  "IAB18-3",
  "IAB18-4",
  "IAB18-5",
  "IAB18-6",
  "IAB19",
  "IAB19-5",
  "IAB19-6",
  "IAB2",
  "IAB20",
  "IAB20-1",
  "IAB20-10",
  "IAB20-11",
  "IAB20-12",
  "IAB20-13",
  "IAB20-14",
  "IAB20-15",
  "IAB20-16",
  "IAB20-17",
  "IAB20-18",
  "IAB20-19",
  "IAB20-2",
  "IAB20-20",
  "IAB20-21",
  "IAB20-22",
  "IAB20-23",
  "IAB20-24",
  "IAB20-25",
  "IAB20-26",
  "IAB20-27",
  "IAB20-3",
  "IAB20-4",
  "IAB20-5",
  "IAB20-6",
  "IAB20-7",
  "IAB20-8",
  "IAB20-9",
  "IAB21",
  "IAB2-1",
  "IAB2-10",
  "IAB2-11",
  "IAB21-1",
  "IAB2-12",
  "IAB21-2",
  "IAB2-13",
  "IAB21-3",
  "IAB2-14",
  "IAB2-15",
  "IAB2-16",
  "IAB2-17",
  "IAB2-18",
  "IAB2-19",
  "IAB22",
  "IAB2-2",
  "IAB2-20",
  "IAB2-21",
  "IAB22-1",
  "IAB2-22",
  "IAB22-2",
  "IAB2-23",
  "IAB22-4",
  "IAB23",
  "IAB2-3",
  "IAB23-1",
  "IAB23-10",
  "IAB23-2",
  "IAB23-3",
  "IAB23-4",
  "IAB23-5",
  "IAB23-6",
  "IAB23-7",
  "IAB23-8",
  "IAB2-4",
  "IAB2-5",
  "IAB2-6",
  "IAB2-7",
  "IAB2-8",
  "IAB2-9",
  "IAB3",
  "IAB3-1",
  "IAB3-10",
  "IAB3-11",
  "IAB3-12",
  "IAB3-2",
  "IAB3-3",
  "IAB3-4",
  "IAB3-5",
  "IAB3-6",
  "IAB3-7",
  "IAB3-8",
  "IAB3-9",
  "IAB4",
  "IAB4-1",
  "IAB4-10",
  "IAB4-11",
  "IAB4-2",
  "IAB4-3",
  "IAB4-4",
  "IAB4-5",
  "IAB4-6",
  "IAB4-7",
  "IAB4-8",
  "IAB5",
  "IAB5-1",
  "IAB5-10",
  "IAB5-11",
  "IAB5-12",
  "IAB5-13",
  "IAB5-14",
  "IAB5-15",
  "IAB5-2",
  "IAB5-3",
  "IAB5-4",
  "IAB5-5",
  "IAB5-6",
  "IAB5-7",
  "IAB5-8",
  "IAB5-9",
  "IAB6",
  "IAB6-1",
  "IAB6-2",
  "IAB6-3",
  "IAB6-4",
  "IAB6-5",
  "IAB6-6",
  "IAB6-7",
  "IAB6-8",
  "IAB6-9",
  "IAB7",
  "IAB7-1",
  "IAB7-10",
  "IAB7-11",
  "IAB7-12",
  "IAB7-13",
  "IAB7-14",
  "IAB7-15",
  "IAB7-16",
  "IAB7-17",
  "IAB7-18",
  "IAB7-19",
  "IAB7-2",
  "IAB7-20",
  "IAB7-21",
  "IAB7-22",
  "IAB7-23",
  "IAB7-24",
  "IAB7-27",
  "IAB7-29",
  "IAB7-3",
  "IAB7-30",
  "IAB7-31",
  "IAB7-32",
  "IAB7-33",
  "IAB7-34",
  "IAB7-35",
  "IAB7-36",
  "IAB7-37",
  "IAB7-38",
  "IAB7-4",
  "IAB7-40",
  "IAB7-41",
  "IAB7-42",
  "IAB7-43",
  "IAB7-44",
  "IAB7-45",
  "IAB7-6",
  "IAB7-7",
  "IAB7-8",
  "IAB7-9",
  "IAB8",
  "IAB8-1",
  "IAB8-10",
  "IAB8-11",
  "IAB8-12",
  "IAB8-13",
  "IAB8-14",
  "IAB8-15",
  "IAB8-16",
  "IAB8-17",
  "IAB8-18",
  "IAB8-2",
  "IAB8-3",
  "IAB8-4",
  "IAB8-5",
  "IAB8-6",
  "IAB8-7",
  "IAB8-8",
  "IAB8-9",
  "IAB9",
  "IAB9-1",
  "IAB9-10",
  "IAB9-11",
  "IAB9-12",
  "IAB9-13",
  "IAB9-14",
  "IAB9-15",
  "IAB9-16",
  "IAB9-17",
  "IAB9-18",
  "IAB9-19",
  "IAB9-2",
  "IAB9-20",
  "IAB9-21",
  "IAB9-22",
  "IAB9-23",
  "IAB9-24",
  "IAB9-25",
  "IAB9-26",
  "IAB9-27",
  "IAB9-28",
  "IAB9-29",
  "IAB9-3",
  "IAB9-30",
  "IAB9-31",
  "IAB9-4",
  "IAB9-5",
  "IAB9-6",
  "IAB9-7",
  "IAB9-8",
  "IAB9-9",
}

export enum LayerType {
  ImageLayer = "imageLayer",
  TextLayer = "textLayer",
}

export enum TransformationType {
  Default = "default",
  UpperCase = "upperCase",
  LowerCase = "lowerCase",
  TitleCase = "titleCase",
}

export enum TextPosition {
  Center = "center",
  TopLeft = "topLeft",
  TopRight = "topRight",
  TopCenter = "top",
  BottomRright = "bottomRight",
  BottomLeft = "bottomLeft",
  BottomCenter = "bottom",
  LeftMiddle = "left",
  RightMiddle = "right",
}

export enum TextAlignment {
  Left = "left",
  Center = "center",
  Right = "right",
}