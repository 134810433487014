export default {
  "public.targetGroup": "Targeting",
  "public.adCorrection": "Proof",
  "public.adBudget": "Purchase",
  "public.sponsored": "Sponsored",
  "public.swipe": "Swipe",
  "public.uniquePerImage": "If unique per object choosen, scroll down to change text",
  "public.uniqueNumberPerImage": "If unique per object choosen, scroll down to change number",
  "public.gender&age": "Gender & Age",
  "public.emailExample": "example@email.com",
  "public.startDate": "Start Date",
  "public.endDate": "End Date",
  "public.topMedia": "Top media",
  "public.social": "Social",
  "public.display": "Display",
  "public.display-dsp": "Display - DSP",
  "public.webTv": "Web-TV",
  "public.video": "Video",
  "public.digitalScreen": "Digital Screen (DOOH)",
  "public.devices": "Devices",
  "public.media": "Media",
  "public.url": "Url",
  "public.clicks": "clicks",
  "public.createLink": "Create link",
  "public.generateReport": "Generate report",
  "public.showFullReport": "Show full report",
  "public.impressions": "Impressions",
  "public.reach": "Reach",
  "public.reachPerPerson": " times/person",
  "public.ctr": "CTR",
  "public.ctrReach": "CTR Reach",
  "public.ctrImpressions": "CTR Impressions",
  "public.channels": "Channels",
  "public.mobile": "Mobile",
  "public.desktop": "Desktop",
  "public.fbMobile": "Facebook mobile",
  "public.fbDesktop": "Facebook desktop",
  "public.mediaBudget": "Media budget",
  "public.geoPlaces": "Geo targeting locations",
  "public.geo": "Geo",
  "public.reportRecipient": "Report Recipients",
  "public.emailReportRecipient": "Report recipient for monthly reporting (Email)",
  "public.campaignManagers": "Campaign Managers",
  "public.newAdImages": "Provide material for your ad",
  "public.currentAdImages": "Current material for your ad",
  "public.adLandingPage": "Landing page",
  "public.report": "Report",
  "public.orderProofStep": "Select Text",
  "public.orderTargetingStep": "Select Geo Targeting Locations & Audience",
  "public.orderLocationStep": "Select Geo Targeting Locations",
  "public.orderBudgetStep": "Select Budget, Schedule & Dashboard Recipients",
  "public.adTextAboveImage": "Ad description above image",
  "public.adTitleBelowImage": "Title below ad",
  "public.descBelowImage": "Description below ad",
  "public.otherImages": "Other materials to use in your ad",
  "public.costCenter": "Cost Center",
  "public.chooseMediaBudget": "Choose Media Budget",
  "public.addLinkedInJobPost": "Choose Media Budget For Job Post On LinkedIn",
  "public.linkedInAmount": "LinkedIn Amount",
  "public.noLinkedJobPost": "No LinkedIn Job Post",
  "public.purchase": "Purchase",
  "public.purchaser": "Purchaser",
  "public.purchased": "Purchased",
  "public.perDayCost": "{value} SEK per day",
  "public.sendNewLogin": "Send New Login",
  "public.extendAd": "Extend Ad",
  "public.adTotal": "Total:",
  "public.targetGroupControlledAds": " Targeted impressions",
  "public.age": "Age",
  "public.gender": "Gender",
  "public.man": "Man",
  "public.men": "Men",
  "public.woman": "Woman",
  "public.unspecified": "Unspecified",
  "public.women": "Women",
  "public.reference": "Reference",
  "public.like": "Like",
  "public.comment": "Comment",
  "public.share": "Share",
  "public.comments": "{value} comments",
  "public.shares": "{value} shares",
  "public.minMaxAge": "Min {min}. Max {max}",
  "public.openNewWindow": "Open in new window",
  "public.order": "Place order",
  "public.next": "Next",
  "public.previous": "Previous",
  "public.adPeriod": "Period",
  "public.companyName": "Company name",
  "public.uploadImage": "Upload image",
  "public.asap": "Start as soon as possible",
  "public.totalValue": "Total {value}",
  "public.lastShowDate": "Dectected end date by default",
  "public.createNewAdMultiEmail":
    "Please specify any additional email addresses you wish to receive the Campaign Dashboard. Multiple email addresses can be added.",
  "public.hintCampaignManagers":
    "Please specify any additional email addresses you wish to be authorized to extend campaign. Multiple email addresses can be added.",
  "public.addNewEmail": "Add email address, multiple email addresses can be provided",
  "public.shortTextRecomended":
    "Keep it short - we recomend {recomendedLength} characters or less. It's currently {value} characters.",
  "public.linkedInReporting": "We do not report automatic targeting ads from LinkedIn",
  "public.vatPrices": "Prices {vatType} {vatSuffix}",
  "public.vatPricesFull": "Prices {vatType} {vatRate}% {vatSuffix}",
  "public.validatingLocations": "Validating geolocations",
  "public.tags": "Add multiple tags, type text and press enter.",
  "public.tenantMediaLocale": "Add locale by typing text and press enter or select from options.",
  "public.tenantMediaTags": "Add multiple tags, type text and press enter or select from options.",
  "public.tenantMediaCategories": "Add text and press enter or select from options.",
  "public.newAdConfirmation": "You will receive a confirmation of your order to the provided email address.",
  "public.adCreated": "The ad has been created and you can now close this window.",
  "public.minBudget": " It needs to be at least {value} impression per day to place the order.",
  "public.dragAndDropImages": `Determine the order and which images to show by "Drag'n'Drop"`,
  "public.addTargetGroupLocation": "Add geolocation targets by searching for places or addresses..",
  "public.more": "...More",
  "public.readMore": "...Read more",
  "public.fileDrop": "Click to choose files or drag them here to upload",
  "public.orders": "Orders",
  "public.exclude": "Ex.",
  "public.include": "Inc.",
  "public.vatSuffix": "VAT",
  "public.vat": "{vatType} {vatRate}% {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Loading",
  "public.newLoginSent": "New Login Sent",
  "public.errorLogin": "Error, please contact",
  "public.adError": "Unable to create ad for",
  "public.requestNewLogin": "Request New Login By Email",
  "public.noImageSelected": "No Image Selected",
  "public.multiImageCategories": "Select one or more image categories",
  "public.infoSpecialAdCategory":
    "Demographic settings locked, in accordance with Meta's (Facebook & Instagram) special ad policy",
  "public.edit": "Edit",
  "public.success": "Success!",
  "public.orderPlaced": "Thank you for your order!",
  "public.hint.image": "Accepted file types: JPG/PNG/BMP",
  "public.hint.font": "Accepted file types: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Date",
  "public.unauthorized": "Unauthorized: Access is denied.",
  "public.permissiondenied": "Access Denied: You do not have permission to view this content",
  "public.additionalInfo": "Additional information",
  "public.DisconnectTenant": "Are you Sure you want to Disconnect {tenantName} from User {userName}",

  "public.recreateCampaignMessage": "Recreate campaign {campaignName} for client {clientName}",
  "public.confirmRecreateCampaign": "Recreate the {selectedChannel} campaign {campaignName} for client {clientName}",

  "public.phoneNumberSettings": "Phone Number Settings",
  "public.genderType": "Gender Type",

  "public.uploadLogoSuccess": "Logo uploaded successfully!",
  "public.uploadLogoFailed": "Logo upload failed!",

  "public.later": "Please come back later",
  "public.noFacebookAd": "Ad not created yet",
  "public.errorMessage": "An error occurred",

  "imageTextOption.same": "Same For All",
  "imageTextOption.unique": "Unique Per Object",

  "calltoaction.NO_BUTTON": "No button",
  "calltoaction.BUY_NOW": "Buy now",
  "calltoaction.APPLY_NOW": "Apply now",
  "calltoaction.BOOK_TRAVEL": "Book travel",
  "calltoaction.WATCH_MORE": "Watch more",
  "calltoaction.CONTACT_US": "Contact us",
  "calltoaction.DOWNLOAD": "Download",
  "calltoaction.GET_OFFER": "Get offer",
  "calltoaction.GET_QUOTE": "Get quote",
  "calltoaction.SIGN_UP": "Sign up",
  "calltoaction.SUBSCRIBE": "Subscribe",
  "calltoaction.INTERESTED": "Interested",
  "calltoaction.GET_SHOWTIMES": "Get showtimes",
  "calltoaction.REQUEST_TIME": "Request time",
  "calltoaction.LEARN_MORE": "Learn more",
  "calltoaction.CALL_NOW": "Call Now",

  "error.required": "This field is required",
  "error.requiredField": "The field {value} is required",
  "error.requiredNumberField": "{value} and is required",
  "error.cpm": "Cpm must be a number in the range 65 to 900",
  "error.maxcpm" : "Cpm value must be upto 250",
  "error.cpm.boundary.min": "Min must be less than max",
  "error.cpm.value": "Cpm must be a number in the range {min} to {max}",
  "error.cpm.boundaries.mixed": "Both min and max must be set",
  "error.number": "This field must be a number",
  "error.chooseOneGender": "* At least one gender",
  "error.dailyBudgetLow": "The media budget is too low, increase the media budget above or shorten the campaign schedule.",
  "error.email": "Provide a valid email",
  "error.generic": "Something went wrong, try again",
  "error.imageUploadError": "Failed to save images",
  "error.oneTargetGroupArea": "At least one geo location",
  "error.locationOverlap": "Geo locations must not overlap",
  "error.noFacebookPage": "Enter your Facebook page url to continue",
  "error.invalidFacebookPage": "The Facebook page is not correct, check your spelling",
  "error.badLink": "The landing page is not correct, check your spelling",
  "error.noLandingPage": "You need to enter your landing page",
  "error.noGenderChoosen": "No gender selected",
  "error.noTargetGroupArea": "At least one geo location must be provided",
  "error.locationNoLocationOverlap": "At least one geo location must be provided and they cannot overlap",
  "error.locationAlreadyExists": "The selected location has already been entered",
  "error.closeEndDate": "End date is only 48h away from start date, end date must be 48h in the future",
  "error.orderCouldNotComplete": "The order failed to process",
  "error.fileTobig": "The file is to big max 50mb, choose another file",
  "error.notImageFile": "The file must be an image, choose another file",
  "error.couldNotExtendProspect": "Could not extend ad, please try again later.",
  "error.couldNotOrderAd": "Could not order ad",
  "error.channelDistribution": "Channel distribution must be 100% in total",
  "error.channelNumbers": "At least 2 channels should be configured",
  "error.inValidEndDate": "End date must be greater then the start date",
  "error.minAge": "Minimum age must be greater then or equal to 18",
  "error.maxAge": "Maximum age must be less then or equal to 100",
  "error.ageValidation": "Max Age must be greater than or equal to Min Age",
  "error.pricingLevel": "At least one pricing level is required",
  "error.pricingLevelMaxValue": "Value must be less then or equal to 200000",
  "error.alphaNumerical": "Please enter only numerical values in the pricing levels",
  "error.templateTags": "Please enter atleast one value in the template tag field",
  "error.maxNum": "Maxmium input number must be less then or equal to 100",
  "error.minNum": "Minimium input number must be greater then or equal to 1",
  "error.uniqueValue": "The field must contain unique values",
  "error.zero": "Value must be above zero",
  "error.noFloat": "The field didn't accept the decimal value",

  "public.error.proofRequired": "At least one image is required",
  "public.error.proofDisplayRequired": "At least {value} images is required",
  "public.error.hasEmptyPhoneNumbers": "The phone number field must not be empty",
  "public.error.file-invalid-type": "Invalid File Type",
  "public.error.file-too-large": "The file is to big max 50mb, choose another file",
  "public.error.too-many-files": "Too Many Files, Pick One",
  "public.error.pageNotFound": "Page not found!",
  "public.error.pageNotFoundDescription": "Please enter a valid url, or contact support.",
  "public.error.pageNetworkError": "Internal Server Error",
  "public.error.pageNetworkErrorDescription": "Please try again later, or contact support.",
  "public.error.landingPages": "All landing pages must be a valid URL.",

  "common.save": "Save",
  "comman.cancel":"Cancel",
  "common.close": "Close",
  "common.choose": "Select",
  "common.pause": "Pause",
  "common.unpause": "Unpause",
  "comman.use":"Use",
  "common.addValue": "Add {value}",
  "common.editValue": "Edit {value}",
  "common.connectTenant": "Connect Tenant",
  "common.disconnectTenant": "Disconnect Tenant",
  "common.createValue": "Create {value}",
  "common.generateValue": "Generate {value}",
  "common.copyValue": "Copy {value}",
  "common.changeValue": "Change {value}",
  "common.removeValue": "Remove {value}",
  "common.pauseValue": "Pause {value}",
  "common.unpauseValue": "Unpause {value}",
  "common.deactivateValue": "Deactivate {value}",
  "common.selectValue": "Select {value}",
  "common.premadeValue": "Premade {value}",
  "common.types": "Types",
  "common.mailContact": "Contact {email}",
  "common.name": "Name",
  "common.category": "Category",
  "common.adAccountStrategy": "Ad Account Strategy",
  "common.adAccountId": "Ad Account Id",
  "common.fallbackPageId": "Facebook Fallback Page Id",
  "common.fallbackLinkedInPageId":"LinkedIn Fallback Page Id",
  "common.dsaBeneficiary": "Beneficiary (DSA)",
  "common.dsaPayor": "Payor (DSA)",
  "common.forceFeedPlacement": "Force Feed placement for all video formats",
  "common.cpm": "CPM",
  "common.targetCpm": "Target Cpm",
  "common.cpm.settings.min": "Min: ≥{value}",
  "common.cpm.settings.max": "Max: ≤{value}",
  "common.defaultMediaImages": "Default Media Images",
  "common.group": "Group",
  "common.reseller": "Reseller",
  "common.fbPage": "Facebook Page",
  "common.externalId": "External Id",
  "common.addMetadata": "Add metadata",
  "common.dataSource": "Datasource",
  "common.activeDataSource":"Active DataSources",
  "common.inactiveDataSource":"Inactive DataSources",
  "common.template": "Template",
  "common.ordererName": "Orderer Name",
  "common.ordererEmail": "Orderer Email",
  "common.deactivate": "Deactivate",
  "common.activate": "Activate",
  "common.actions": "Actions",
  "common.totalAmount": "Total Amount",
  "common.conversionRate": "Conversion Rate",
  "common.thirtyTotalConversionRate": "30days {thirty}% (Total {total}%)",
  "common.cancelOrder": "Cancel order",
  "common.cancelCampaign": "No, cancel",
  "common.confirmRecreateCampaign": "Yes, Recreate",
  "common.removeItem": "Remove Item",
  "common.configureTemplateData": "Configure Template Data",
  "common.removeClient": "Remove Client",
  "common.addTenant": "Add Tenant",
  "common.partnerImages": "Partner Logos",
  "common.validationMessage": "Please Enter Pricing Levels",
  "common.acceptNumericNumbers": "Pricing Levels must only contain numeric values",
  "common.sendDraftMails": "Send Draft Email",
  "common.updatedBy": "Updated By",
  "common.pinMeTo": "PinMeTo",
  "common.pinMeToAdAccountId": "PinMeTo Adaccount Id",
  "common.pinMeToAppID": "PinMeTo App Id",
  "common.pinMeToAppSecret": "PinMeTo App Secret",

  "common.created": "Created",
  "common.prospect": "Prospect",
  "common.prospectSource": "Prospect Source",
  "common.prospectSources": "Prospect Sources",
  "common.noEditValue": "{value} Not Editable",
  "common.noRemoveValue": "{value} Not Removable",
  "common.rename": "Rename",
  "common.prolong": "Prolong Campaign",
  "common.recreate": "Recreate",
  "common.recreateCampaign": "Recreate Campaign",
  "common.recreateWarning": "Warning, are you sure that you want to:",
  "common.isStartDate": "Add budget start date",
  "common.configure": "Configure",
  "common.used": "Used",
  "common.objectAmount": "Object Amount",
  "common.modified": "Modified",
  "common.failure": "Failure",
  "common.dataChange": "Data Change",
  "common.spidered": "Crawled",
  "common.imageLibrary": "Image library",
  "common.dynamic": "Dynamic/Crawled",
  "common.static": "Static",
  "common.budget": "Budget",
  "common.setBudgetStartDate": "Set Start Date",
  "common.order": "Order",

  "common.remaining": "Remaining",
  "common.delete": "Delete",
  "common.end": "End",
  "common.all": "All",
  "common.noCategory": "No Category",
  "common.create": "Create",
  "common.clearAll": "Clear All",
  "common.Price": "Price",
  "common.email": "Email",
  "common.returnToDashboard": "Return To Dashboard",
  "common.mobileNumber": "Mobile Number",
  "common.send": "Send",
  "common.title": "Title",
  "common.password": "Password",
  "common.login": "Login",
  "common.logout": "Logout",
  "common.client": "Client",
  "common.active": "Active",
  "common.opened": "Opened",
  "common.completion": "Completion",
  "common.remove": "Remove",
  "common.error": "Error, Contact IT",
  "common.filters": "Filters",
  "common.createdBy": "Creator",
  "common.field": "Field",
  "common.type": "Type",
  "common.patterns": "Patterns",
  "common.request": "Request",
  "common.description": "Description",
  "common.phoneNumber": "Phone Number",
  "common.themeColor": "Theme Color",
  "common.clientLogo": "Client Logo",
  "common.editGroup": "Edit Group",
  "common.yes": "Yes",
  "common.no": "No",
  "common.api": "API",
  "common.enable": "Enabled",
  "common.disable": "Disabled",
  "common.valid": "Valid",
  "common.invalid": "Invalid",

  "common.january": "January",
  "common.february": "February",
  "common.march": "March",
  "common.april": "April",
  "common.may": "May",
  "common.june": "June",
  "common.july": "July",
  "common.august": "August",
  "common.september": "September",
  "common.october": "October",
  "common.november": "November",
  "common.december": "December",

  "common.search": "Search...",
  "common.inactive": "Inactive",
  "common.showInactive": "Show Inactive",
  "common.showSandboxed": "Show Sanboxed",
  "common.copied": "Copied!",
  "common.copyCollectionValue": "Copy {length} of {collection} IDs",
  "common.campaign": "Campaign",
  "common.tenant": "Partner",
  "common.targeting": "Targeting",
  "common.budgetPeriod": "Budget Period",
  "common.currencyCode": "Currency code",
  "common.maxItemsInOrder": "Max Items In Order",
  "common.languageCode": " Language code",
  "common.locale&currency": " Locale & Currency",
  "common.value": "value",
  "common.averageValue": "Average {value}",

  "common.copy": "Copy",
  "common.role": "Role",
  "common.status": "Status",
  "common.facebook": "Facebook",
  "common.bidTheatre": "Bidtheatre",
  "common.linkedIn": "LinkedIn",
  "common.linkedInFallbackPageId": "LinkedIn Fallback Page Id",
  "common.clearChannel": "Clear Channel",
  "common.youTube": "YouTube (DV360)",
  "common.youTubeGoogleAds": "YouTube (GoogleAds)",

  "common.textFormat": "Text Format",
  "common.rootTextFormat": "Root Text Format",
  "common.itemTextFormat": "Item Text Format",
  "common.saveAndContinue": "Save and continue",
  "common.exitWithoutSaving": "Exit without saving",
  "common.resetPassword": "Reset Password",
  "common.id": "Id",
  "common.path": "Path",
  "common.format": "Format",
  "common.duration": "Duration",
  "common.items": "Items",
  "common.item": "Item",
  "common.groupings": "Groupings",
  "common.previous": "Previous",
  "common.previousStep": "Previous step",
  "common.next": "Next",
  "common.page": "Page",
  "common.pageSize": "Page size",
  "common.crawlingSpeed": "Crawling speed",
  "common.showDayAndTime": "Day and Time Interval",
  "common.customDateAndTime": "Date And Time",
  "common.crawlingSpiderSpeed": "Spider Speed",
  "common.customDaySpeed": "Days Of Week",
  "common.customHourSpeed": "Hours",
  "common.customMinuteSpeed": "Minutes",
  "common.cronExpression": "CRON expression",
  "common.cronStatement": "OR you can use CRON expression below",
  "common.cancelReason": "Cancel reason",
  "common.displayName": "Display name",
  "common.tenantBranch": "Tenant Branch",
  "common.groupName": "Group name",
  "common.groupId": "Group id",
  "common.facebookPageId": "Facebook page id",
  "common.billingType": "Billing type",
  "common.massSelection": "Mass Selection",
  "common.massZipCode": "Add Zip Codes",
  "common.zipCode": "Zip Code",
  "common.none": "None",
  "common.slug": "Slug",
  "common.access": "Access",

  "common.orderTemplateItem": "Order Item",
  "common.orderTemplate": "No orders template available. Please create a new order template",

  "common.logoTitle": "Choose {value} for Partner",

  "confirm.removeAutomations": "Are you certain you want to permanently delete this automations?",
  "confirm.removeRecipientGroup": "Are you certain you want to permanently delete this recipient group?",
  "confirm.removeValue": "Are you certain you want to permanently delete {value}?",
  "confirm.removeScheduledReport": "Are you certain you want to remove this scheduled report?",
  "confirm.remove": "Are you certain you want to remove?",
  "confirm.removeProvider": "Are you certain you want to permanently delete Provider {providerIndex}?",
  "confirm.openNewWindow": "Are you certain you want to open new window?",
  "confirm.removeObjectName": "Are you certain you want to permanently delete this {value}?",
  "confirm.archive": "Are you certain you want to archive?",
  "confirm.deactivate": "Are you certain you want to deactivate {value}?",
  "confirm.activate": "Are you certain you want to activate {value}?",
  "confirm.removePrompt": "Are you certain you want to remove {value}?",
  "confirm.copyValue": "Are you certain you want to copy {value}?",
  "confirm.exitWithoutSaving": "Your changes will be lost. Are you sure you want to leave?",
  "confirm.resetPassword": "Are you sure you want to reset your password?",
  "confirm.cancel": "Are you sure you want to cancel order {value}?",
  "confirm.unpause": "Are you certain you want to unpause?",
  "confirm.generateKeys":
    "When a key has already been generated and a new one is generated, the old one will disappear and stop working. Are you sure you want to generate a new key?",
  "confirm.insightSampleData": "Are you certain you want to create insights sample data?",
  "confirm.continueWithSavedHandover":
    "Do you want to continue with the saved handover (press I want to continue) or start from the beginning (press I want to start over)?",
  "confirm.continue": "I want to continue",
  "confirm.restart": "I want to start over",
  "confirm.startedHandover": "Started handover",
  "confirm.useInactiveDataSourceOnCampaign":
    "Are you sure you want to set the inactive data source ({dataSource}) on {campaign}?",

  "error.notValidInterest": "Not a valid interest",
  "error.noCampaignsFound": "No Campaigns Found.",
  "error.lowValue": "Value to low, min {value}",
  "error.highValue": "Value to high, max {value}",
  "error.noFieldsFound": "No Fields Found",
  "error.pricePackage.small": "Small must be less then medium",
  "error.pricePackage.medium": "Medium must be greater then small and less then large",
  "error.pricePackage.large": "Large must be greater then small/medium less then max amount",
  "error.pricePackage.max": "Max amount must be greater then large",
  "error.automations": "If the automation contains one recipient it must have a name and valid email",
  "error.recipientGroups":
    "Recipient groups must contain one recipient per recipient group, recipient name must not be empty and have valid email",
  "error.dataSource": "The data source can't be empty or invalid JSON",
  "error.campaignSelectionNotSameGroupClient": "All chosen campaigns doesn't have the same group/client",
  "error.couldNotCreateCollectionReport": "Could not create collection report",
  "error.editLogo": `Couldn't update logo, contact tech!`,
  "error.canNotChangeGroup": "Can't change group",
  "error.budgetOrSubscription": "Campaigns cannot be created without a budget or subscription",
  "error.languageCode": "Not a valid language code, ex en-au or sv",
  "error.currencyCode": "Not a valid currency code, ex SEK or USD",
  "error.noWhitespace": "Whitespace not allowed in value",
  "error.uppercase": "Value needs to be in uppercase",
  "error.lowercase": "Value needs to be in lowercase",
  "error.clientNameUnique": "Client name must be unique, already exists in group!",
  "error.groupNameUnique": "Group name must be unique, already exists in partner!",
  "error.partnerNameUnique": "Parner name must be unique, already exists!",
  "error.nameUnique": "Name must be unique!",
  "error.minTwo": "Value needs to be at least 2 characters long",
  "error.keyRequired": "Key is required",
  "error.keyIsDuplicated": "The key is not unique",
  "error.login": "Invalid Login Credentials",
  "error.emailUnique": "User with that email already exists",
  "error.tenantManager": "Cannot create a tenant manager without specifying tenant",
  "error.rolePassword": "Cannot create a admin or tenant manager without a password",
  "error.valueUndefined": "{value} undefined",
  "error.url": "Field must be a valid URL",
  "error.rootLink": "Root requires a link field",
  "error.idsUnique": "Ids must be unique",
  "error.oneItem": "One item is required",
  "error.deliveryGuarantee": "Delivery guarantee must be a number and in range 1 to 100",
  "error.idsMaxItems": "Too many Ids, max 30",
  "error.minDaysToGoLive": "Date should be at least 7 days from today",
  "error.campaignType": "Select a campaign type option",

  "error.errorMessageMoreMinute": "Minutes must be greater than 0",
  "error.errorMessageLessMinute": "Minutes must be at most 59",
  "error.errorMessageMoreHours": "Hours must be greater than 0",
  "error.errorMessageLessHours": "Hours must be at most 23",

  "success.collectionReport": "Collection report created and sent to tech@spiderads.io",
  "success.createDataSource": `Data Source Created!`,
  "success.editDataSource": `Data Source Edited!`,
  "success.editThemeColor": `Themecolor Edited!`,
  "success.editLogo": `Logo Updated!`,
  "success.userCreated": `User Created!`,
  "success.editRole": `Role Updated!`,
  "success.editMobileNumber": `Mobile Number Updated!`,

  "hint.usedBy": "Used by {campaigns}",
  "hint.notInUse": "Not in use",
  "hint.channelProgress": `Total: {impressions} Booked: {bookedImpressions} Delivered: {deliveredImpressions} Completion: {completion}%`,
  "hint.addRecipient": "Add recipient by entering an valid email",
  "hint.addCategory": "Add category, or leave field empty for no category",
  "hint.colorPicker": "Choose color from palette or paste in hex color code",
  "hint.warning.display": "Make sure chosen template, is configured for bidTheatre",
  "hint.massSelection": "Paste {value} comma separated or with line breaks",
  "hint.allCampaignsSceduledReport": "Leave empty for all campaigns on client",
  "hint.fillAllFields": "You must fill in all fields in order to add a contact",
  "hint.addFilledContact": 'Click the "Add Contact" button to add the contact.',

  "tooltip.campaignSelection": "Campaigns you've chosen in the list",
  "tooltip.pickTemplateMassUpdate": "Pick the template you want the campaign selection to be updated to",
  "tooltip.overrideLabel": "If you wish to override the default label: Reference (Reference number, cost center or person)",
  "tooltip.prefilledField":
    "If you wish to override the default prefilled field: Reference (Reference number, cost center or person)",
  "tooltip.overridePlaceholder":
    "If you wish to override the default placeholder: Reference (Reference number, cost center or person)",
  "tooltip.dataSourceName": "The name you want to on the data source",
  "tooltip.inheritDataSource": "The data source you wish to inherit settings from",
  "tooltip.inheritFilters": "If you wish to combine or reset filters from the inherited data source",
  "tooltip.variables": "Variables you might wanna use ex: url",
  "tooltip.url": "Insert the Url path to the webpage you wish to fetch metadata from",
  "tooltip.headers": "If you wish to add request headers, ex: ':scheme: https'",
  "tooltip.userAgent":
    "If the webpage doesnt allow unknown user agents, ex: Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
  "tooltip.formData": "If you wish to add form data to the request",
  "tooltip.numberOfResults": "If you wish limit the amount of results",
  "tooltip.rootData": "For shared ads, put title and description in root",
  "tooltip.itemsData": "For invidual ads, put title and description in items",
  "tooltip.container": "Which html tag or Json key you wish the data source should start fetching from ex: $.items[*]",
  "tooltip.automationCreated":
    "Automations will only automate prospects found after the automation was created, existing prospect will not be affected",
  "tooltip.defaultBudgetAmount": "Fallback Budget Amount on automatic orders, can be overridden in datasource",
  "tooltip.defaultDuration": "Fallback Duration (Days) on automatic orders, can be overridden in datasource",
  "tooltip.defaultGeo": "Fallback Geo on automatic orders, can be overridden in datasource",
  "tooltip.defaultRadius": "Fallback radius on automatic orders, default is 3km",
  "tooltip.sorting": "If you want the spider to sort the results in ascending or descending order.",
  "tooltip.totalClicksValue": "Total Clicks: {value}",
  "tooltip.allowedFontFamily": "Only ttf/woff fonts allowed",
  "tooltip.image": "Choose an image as value",
  "tooltip.reasonedAction": "Reason: {reason}, Date: {timestamp}",
  "tooltip.fieldDescription": "Fill in the field {value}",
  "tooltip.textAboveAdSoMe": "Fill in if there should be a static text above the advertisement image",
  "tooltip.titelTextDisplay": "Fill in if there should be a static title text",
  "tooltip.maxProducts": "A carousel is a maximum of 10 objects",

  "title.automaticReport": "Automatic performance report via email",
  "title.pdfUpload": "{value} Pdf Upload",
  "title.uploadPdf": "Pdf Upload",
  "title.weeklyPdf": "Weekly Pdf",
  "title.uploadPdfFile": "Upload new pdf",
  "title.selectPdf": "Select pdf",

  "title.newAdImges": "Images for ad (Image ad when only one image otherwise a carousel ad)",
  "title.companyFacebookPage": "Facebook page",
  "title.lockGeoTargets": "Lock Geo Target",
  "title.orderRecived": "Order is received",
  "title.resultReport": "Performance report",
  "title.numAdShow": "The number of times the ad has been shown",
  "title.numAdClickSee": "How many people who saw the ad clicked on it",
  "title.numAdClick": "How many clicks on the ad",
  "title.fb&bt": "Facebook {fb}% - Bidtheatre {bt}%",
  "title.channelShare": "Channel Share",
  "title.useCustomStaticCreatives": "Custom Static Creatives",
  "title.createCampaign": "Create Campaign",
  "title.cpmValue": "Cpm {value}",
  "title.campaignCategory": "Campaign Category",
  "title.campaignDefaults": "Campaign Defaults",
  "title.clients": "Clients",
  "title.latestProspect": "Latest Prospect",
  "title.latestOrder": "Latest Order",
  "title.numberOfProspect": "Number Of Prospect",
  "title.configStaticData": "Configure Static Data Source",
  "title.createAdvancedDataSource": "Create Advanced Data Source",
  "title.createStaticDataSource": "Create Static Data Source",
  "title.dataSources": "Data Sources",
  "title.notes": "Notes",
  "title.templates": "Templates",
  "title.archive": "Archive",
  "title.viewInBaka": "View In Baka",
  "title.campaigns": "Campaigns",
  "title.settings": "Settings",
  "title.videoTemplates": "Video Templates",
  "title.userDrafts": "User Drafts",
  "title.pdfLibrary": "PDF Library",
  "title.videoPlacements": "Video Placements",
  "title.groups": "Groups",
  "title.orders": "Orders",
  "title.createNewCampaign": "Create New Campaign",
  "title.createBudgetPeriod": "Create Budget Period",
  "title.budgetPeriods": "Budget Periods",
  "title.clientDashboardCampaigns": "Clientdashboard/Campaigns",
  "title.value": "Value",
  "title.clientDashboard": "Client dashboard",
  "title.images": "Images",
  "title.uploadImages": "Upload Images",
  "title.schedule": "Schedule",
  "title.recipients": "Recipients",
  "title.specifiedEmail": "Specified Email Address",
  "title.recipient": "Recipient",
  "title.scheduledReport": "Scheduled Report",
  "title.scheduledReports": "Scheduled Reports",
  "title.specifiedEmailadress": "Enter Email",
  "title.sendOrderEmail": "Send order email to specific email",
  "title.allCampaigns": "All Campaigns",
  "title.previewProspect": "Preview Prospect",
  "title.canAddLinkedInBudget": "Can Add LinkedIn Budget",
  "title.canAddCampaignManagers": "Can Add Campaign Manager",
  "title.hasMassSelection": "Has Mass Selection Images",
  "title.updateforallclients": "Include All Clients",
  "title.hasMassZipCode": "Has Mass ZipCode",
  "title.applyMarginSetting": "Apply MargingWRTSplash",
  "title.mediaLibararyCategory": "Category For Media Library",
  "title.prefix": "Camapign Name Prefix",
  "title.suffix": "Camapign Name Suffix",
  "public.costCenterreferencefield": "Costcenter/referencefield",
  "title.customfieldType": "Customfield Type",
  "title.filtering": "Filtering",
  "title.customField": "Custom Field",
  "title.sorting": "Sorting",
  "title.prospectType": "Type Of Prospect",
  "title.staticDataSource": "Static Data Source",
  "title.dynamicDataSource": "Dynamic Data Source",
  "title.canUpload": "Image Upload Settings",
  "title.endDateSetting": "End date setting",
  "title.preview": "Preview",
  "title.recipientFilter": "Recipient Filter",
  "title.addAutomation": "Add Automation",
  "title.trigger": "Trigger",
  "title.addTrigger": "Add Trigger",
  "title.pricePackage.small": "Price package (Small)",
  "title.pricePackage.medium": "Price package (Medium)",
  "title.pricePackage.large": "Price package (Large)",
  "title.maxAmount": "Maximum amount",
  "title.pricePackage.single": "Price package (Single)",
  "title.automationsSettings": "Automations Settings",
  "title.defaultBudgetAmount": "Fallback Budget Amount",
  "title.defaultDuration": "Fallback Duration (Days)",
  "title.defaultGeo": "Fallback Geo",
  "title.defaultRadius": "Fallback Radius",
  "title.addFilter": "Add Filter",
  "title.recipientGroups": "Recipient Groups",
  "title.fallbackRecipient": "Use as fallback recipient(s) only if no recipient is found on the prospect",
  "title.addRecipient": "Add Recipient",
  "title.addRecipientGroup": "Add Recipient Group",
  "title.removeTargeting": "Remove targeting",
  "title.removeRecipientGroup": "Remove Recipient Group",
  "title.removeAutomation": "Remove Automation",
  "title.pricePackageCurrencyCode": "Price Package In {value}",
  "title.dataSourceName": "Data Source Name",
  "title.providers": "Providers",
  "title.evaluated": "Evaluated",
  "title.evaluate": "Evaluate",
  "title.result": "Result",
  "title.output": "Output",
  "title.managers": "Managers",
  "title.manager": "Manager",
  "title.orderNumber": "Order Number",
  "title.prospect": "Prospect",
  "title.amount": "Amount",
  "title.allowCallAds": "Allow Call Ads",
  "title.massUpdateCampaign": "Edit template for selected campaigns",
  "title.addNote": "Add Note",
  "title.campaignSelection": "Selected Campaigns",
  "title.SelectedValue": "Selected {value}",
  "title.campaignSelectionValue": "{value} Campaigns Selected",
  "title.dataSourceSelectionValue": "{value} Data Sources Selected",
  "title.exportToExcel": "Export to Excel",
  "title.createCollectionReport": "Create Collection Report",
  "title.overrideLabel": "Override Label",
  "title.prefilledField": "Pre-populated cost center",
  "title.overridePlaceholder": "Override Placeholder",
  "title.exclusions": "Exclusions",
  "title.addExclusions": "Add Exclusions",
  "title.spiderTemplate": "Template",
  "title.inheritSettings": "Inherit Settings",
  "title.variables": "Variables",
  "title.addVariable": "Add Variable",
  "title.container": "Container",
  "title.userAgent": "User Agent",
  "title.restrictNumberOfResults": "Restrict Number Of Results",
  "title.sortResult": "Sort Result",
  "title.numberOfResults": "Number Of Results",
  "title.headers": "Headers",
  "title.formData": "Form-Data",
  "title.addHeader": "Add Header",
  "title.addFormData": "Add Form-Data",
  "title.key": "Key",
  "title.rootData": "Root Data",
  "title.addRootData": "Add Root Data Field",
  "title.replacements": "Replacements",
  "title.addReplacement": "Add Replacement",
  "title.addContact": "Add contact",
  "title.addTargeting": "Add targeting",
  "title.uploadTemplate": "Upload template",
  "title.removeRootData": "Remove Root Data Field",
  "title.selector": "Selector",
  "title.transformScript": "Transformation Script",
  "title.pattern": "Pattern",
  "title.replacement": "Replacement",
  "title.recursive": "Recursive",
  "title.itemsData": "Items Data",
  "title.addItemData": "Add Item Data Field",
  "title.removeItemData": "Remove Item Data Field",
  "title.addProvider": "Add Provider",
  "title.typeOfFilter": "Type Of Filter",
  "title.requestVerificationToken": "Request Verification Token",
  "title.requestVerificationUrl": "Request Verification Url",
  "title.requestCredentialsToken": "Request Credentials Token",
  "title.requestCredentialsToken.url": "Authentication Endpoint",
  "title.requestCredentialsToken.tokenSelector": "Token Selector",
  "title.resolveCanonical": "Resolve canonical urls",
  "title.subscription": "Subscription",
  "title.isTryAndBuy": "Try & Buy",
  "title.monthlyBudget": "Monthly Budget",
  "title.sameForAllMonths": "Same budget all months",
  "title.users": "Users",
  "title.user": "User",
  "title.manuallyEnded": "Manually Ended",
  "title.updateCampaigns": "Update Campaigns",
  "title.updateProspectSources": "Update Prospect Sources",
  "title.tags": "Tags",
  "title.locale": "Locale",
  "title.mediaCategories": "Media Category",
  "title.brokerageFee": "Brokerage Fee",
  "title.metadata": "Metadata",
  "title.sandbox": "Sandbox",
  "title.templateName": "Give your template a name",
  "title.billingType": "Billing Type",
  "title.deliveryGuarantee": "Delivery Guarantee",
  "title.background": "Background",
  "title.color": "Color",
  "title.image": "Image",
  "title.size": "Size",
  "title.landscapeSize": "Landscape Size",
  "title.portraitSize": "Portrait Size",
  "title.positionY": " Position Y",
  "title.dimmerColor": "Dimmer Color",
  "title.dimmerOpacity": "Dimmer Opacity",
  "title.defaults": "Defaults",
  "title.sampleEmail": "Sample Email",
  "title.editClientEmailStopSettings": "Edit Client Email Setting",
  "title.editCampaignEmailStopSettings": "Edit Campaign Email Setting",
  "title.emailType": "Type of email",
  "title.selectedDay": "Selected Day",
  "title.emailTemplateSettings": "Email template settings",
  "title.headerFont": "Header font",
  "title.contentFont": "Content font",
  "title.supportBox": "Support box",
  "title.footer": "Footer",
  "title.emailTemplateInfo": "Email template info",
  "title.subject": "Subject",
  "title.markdownContent": "Markdown Content",
  "title.emailTemplateType": "Email template type",
  "title.metaMedia": "Meta Media (Facebook & Instagram)",
  "title.website": "Website",
  "title.appDomain": "App domain",
  "title.corsAllowedDomains": "Allowed Additional Domains",
  "title.cdnDomain": "CDN domain",
  "title.emailDomain": "Email domain",
  "title.fromEmail": "From email",
  "title.productTitle": "Product title",
  "title.productLink": "Product link",
  "title.smsSender": "SMS sender",
  "title.supportEmail": "Support email",
  "title.orderRecipient": "Order recipient",
  "title.apiChannels": "Allowed Api Channels",
  "title.state": "State",
  "title.reason": "Reason",
  "title.dataSourceFailures": "Data Source Failures",
  "title.profiles": "Profiles",
  "title.insightsKey": "Insights key",
  "title.apiKey": "Api key",
  "title.saveKey": "Save the accesskey, it is not possible to get the key again without recreate a new key.",
  "title.documentation": "Documentation: {value}",
  "title.magicTokenUsages": "Magic Token Usages",
  "title.fallbackFacebookPageId": "Fallback Facebook PageId",
  "title.fallbackLinkedInPageId": "Fallback LinkedIn PageId",
  "title.mediaLibrarySettings": "Medialibrary Settings",
  "title.allowPublicClientMetadata": "Allow Public Client Metadata",
  "title.insightsSampleData": "Insights Sample data",
  "title.prospecting": "Prospecting",
  "title.integrationModule": "Integration Module",
  "title.partnerApi": "Partner API",
  "title.emailSelection": "Email Selection",
  "title.stopEmailSelection": "Choose below which automatic email you want to stop",
  "title.creationSource": "Created from",
  "title.emails": "Emails",
  "title.opened": "Opened",
  "title.clientLiveDate": "When will the customer go live",
  "title.contractPeriod": "Contract period",
  "title.url": "Url",
  "title.landingPage": "Landing page",
  "title.textAboveAdSoMe": "Text above ad image (SoMe)",
  "title.titelTextDisplay": "Title text (Display)",
  "title.agreement": "Is there a signed agreement",
  "title.maxProducts": "Max number of products / items in order",
  "title.mailRecipient": "Mail recipient",
  "title.mailSettings": "Mail settings",
  "title.mailDay": "What day should the email be sent out",
  "title.mailTime": "What time should the email be sent out",
  "title.customerAndBillingInfo": "Customer & Billing information",
  "title.orgNumber": "Organization number",
  "title.address": "Adress",
  "title.clientContacts": "Client contacts",
  "title.generalCampaignSettings": "General campaign settings",
  "title.campaignType": "Campaign type",
  "title.pricePackageSettings": "Price package settings",
  "title.verticals": "Verticals",
  "title.mediaDistribution": "Choose distribution between media",
  "title.clientFBPage": "Faceebook Page (Sender in the Meta Ads)",
  "title.costcenter": "Potential cost center on invoice",
  "title.OrderTemplateSettings": "Order Template Settings",
  "title.campaignName": "Campaign Name",
  "title.addfield": "Add field",
  "title.adAccountId": "Ad Account Id",
  "title.linkedInPageId": "LinkedIn Page Id",
  "title.linkedInAdAccountId": "LinkedIn AdAccount Id",
  "title.hasSinglePrice": "Single Pricing",
  "title.useStaticTitle": "Use Static Title",
  "title.language": "Language",
  "title.OrderItemsForm": "Add OrderItems Form",
  "title.ConfigureOrderTemplateItem": "Configure OrderTemplate Items",
  "title.orderTemplateName": "Order Template Name",
  "title.initialPrice": "Initial Price",
  "title.pricingLevels": "Pricing Levels",
  "title.templateTags": "Template Tags",
  "title.reportReciepient": "Report Reciepient",
  "title.imageCategories": "Image Categories",
  "title.campaignDataItemLandingPage": "Campaign Landing Page",
  "title.campaignDataLogoUrl": "Logo Link",
  "title.campaignDataHeader": "Campaign Header",
  "title.campaignDataItemDefaultTitle": "Campaign Default Title",
  "title.campaignDataItemDefaultSubTitle": "Campaign Default SubTitle",
  "title.campaignDataItemImageUrl": "Campaign Default ImageUrl",
  "title.OrderTemplateSettingsForm": "Order Template Setting Form",
  "title.ConfigureLocationItem": "Template Location",
  "title.OrderLocationItemsForm": "Template Location",
  "title.ConfigureCordinates": "Template Cordinates",
  "title.campaignLocationItemName": "Campaign Location name",
  "title.campaignDataItemRadius": "Campaign Radius",
  "title.campaignLocationLat": "Campaign Location Latitude",
  "title.campaignLocationLong": "Campaign Location Longitude",
  "title.OrderCordinateItemsForm": "Order Cordinates",
  "title.campaignData": "Campaign Data",
  "title.locationAndCoordinates": "Location And Co-ordinates",
  "title.referenceSettings": "Reference Settings",
  "title.referenceSettingsLabel": "Label",
  "title.referenceSettingsplaceholder": "PlaceHolder",
  "title.ReferenceSettingvalue": "Value",
  "title.SelectedOrderTemplate": "Select Order Template",
  "title.createOrderTemplate": "Create Order Template",
  "title.editOrderTemplate": "Save Order Template",
  "title.minAge": "Min Age",
  "title.maxAge": "Max Age",
  "title.phoneNumber": "Phone Number",
  "title.carouselAd": "Carousel ad",
  "title.callAd": "Call ad",
  "title.handover": "Handover",
  "title.telemetry": "Telemetry",
  "title.cpmSettings": "Cpm settings",
  "title.enablePinMeTo": "Enable PinMeTo",
  "title.pinMeToCampaign": "Set as PinMeTo Campaign",
  "title.integratePinMeToCampaign":
    "Campaign {integratedCampaign} is integrated. Confirm to override with Campaign {campaignName}",
  "title.fanout": "Fanout",
  "title.fanoutAllClients": "Select all available clients on group",
  "title.addFanout": "Add Fanout",
  "title.removeFanout": "Remove Fanout",
  "title.updated": "Updated",

  "placeholder.noGroup": "No Group",
  "placeholder.noReseller": "No reseller",
  "placeholder.noDataSourceChoosen": "No Data Source Choosen",
  "placeholder.noTemplateChoosen": "No Template Choosen",
  "placeholder.mobileExample": "+46723395200",
  "placeholder.chooseColor": "Choose color",
  "placeholder.chooseCategory": "Choose category",
  "placeholder.noTenant": "No Partner",
  "placeholder.chooseLanguage": " Choose Language",
  "placeholder.noAddedContacts": "No contacts have been added",
  "placeholder.selectClients": "Select clients",

  "handover.title.salesman": "Enter the seller's details",
  "handover.title.customer": "Fill in the end customer's details",
  "handover.title.mediaDistribution": "Ad Campaign Settings & Information",
  "handover.title.campaignDetails": "Enter settings for your campaign",
  "handover.navigation.salesman": "Seller Information",
  "handover.navigation.customer": "Customer Details",
  "handover.navigation.mediaDistribution": "Media Management",
  "handover.navigation.campaignDetails": "Campaign Settings",

  "handover.label.name": "Your name",
  "handover.label.email": "Your Email",
  "handover.label.companyName": "Company Name",
  "handover.label.postTown": "PostTown",

  "handover.helper.fieldDescription": "Fill in {value}",
  "handover.helper.clientFieldDescription": "Fill in the end customer's {value}",
  "handover.helper.name": "handover helper name.",
  "handover.helper.email": "Email of the responsible seller.",
  "handover.helper.companyName": "the name of the company you work for.",
  "handover.helper.emailForBilling": "for billing.",
  "handover.helper.emailForReport": "Responsible person's email, if any. Multiple email addresses can be specified",

  "handover.success": "Thank you for your handover!",
  "handover.success.description": "You will receive a confirmation of your handover to your provided email address.",

  "telemetry.delivery": "Delivery last 30 days",

  "options.approvedAdTemplate": "Approved ad template exists",
  "options.deliveryOrders": "Delivery orders",

  "campaignType.alwaysOn": "Always On",
  "campaignType.semiAutomatic": "Semi Automatic",
  "campaignType.fullyAutomatic": "Fully automatic One-time Advertising",
  "campaignType.weeklyBoost": "Weekly Boost",

  "campaignCategory.recruitment": "Recruitment",
  "campaignCategory.carDealers": "Cars & Special Vehicles",
  "campaignCategory.realEstateAgents": "Real Estate Agents",
  "campaignCategory.propertyOwners": "Property Owners & Leasers",
  "campaignCategory.pressReleases": "Press Releases & News",
  "campaignCategory.retailFewStores": "Retail (For just 1-5 stores of the same chain)",
  "campaignCategory.retailManyStores": "Retail (For 6 or more stores of the same chain)",

  "sliderLabel.fb&bt0": "Facebook {value}%",
  "sliderLabel.fb&bt1": "Bidtheatre {value}%",
  "sliderLabel.facebook": "Facebook {value}%",
  "sliderLabel.bidTheatre": "Bidtheatre {value}%",
  "sliderLabel.youTube": "YouTube {value}%",
  "sliderLabel.youTubeGoogleAds": "YouTube {value}%",

  "documentTitle.resultReport": "Performance report for {campaignName}",

  "filterType.equals": "Equals",
  "filterType.contains": "Contains",
  "filterType.notContains": "Not Contains",
  "filterType.lessThan": "Less Than",
  "filterType.greaterThan": "Greater Than",
  "filterType.hasValue": "Has Value",
  "filterType.noValue": "Has No Value",
  "filterType.regex": "Regex match",
  "filterType.regexInverted": "Negative Regex",
  "filterType.in": "In",
  "filterType.notIn": "Not In",

  "filterLogicType.or": "Or",
  "filterLogicType.and": "And",
  "filterLogicType.or_not": "Or not",
  "filterLogicType.and_not": "And Not",

  "scheduledReportType.weekly": "Weekly",
  "scheduledReportType.monthly": "Monthly",
  "scheduledReportType.quaterly": "Quaterly",
  "scheduledReportType.yearly": "Yearly",

  "referenceType.none": "Dont show",
  "referenceType.optional": "Optional",
  "referenceType.required": "Required",
  "referenceType.locked": "Locked",

  "prospectType.object": "Object",
  "prospectType.job": "Job",
  "prospectType.offer": "Offer",
  "prospectType.realEstate": "Real Estate",

  "landingPageSetting.uneditable": "Uneditable",
  "landingPageSetting.same": "Same For All",
  "landingPageSetting.unique": "Unique",

  "referenceSettings.none": "None",
  "referenceSettings.optional": "Optional",
  "referenceSettings.required": "Required",
  "referenceSettings.locked": "Locked",

  "phoneNumberSettings.disabled": "Disabled",
  "phoneNumberSettings.unique": "Unique",
  "phoneNumberSettings.same": "Same For All",

  "canUploadSetting.no": "No Upload",
  "canUploadSetting.toProspect": "Can upload to prospect",
  "canUploadSetting.toClient": "Can upload to medialibrary on client",
  "canUploadSetting.toHQ": "Can upload to medialibrary on HQ",

  "channelCampaignState.active": "Active",
  "channelCampaignState.ended": "Ended",
  "channelCampaignState.scheduled": "Scheduled",
  "channelCampaignState.warning": "Warning",
  "channelCampaignState.broken": "Broken",

  "enum.campaignStateType.archived": "Archived",
  "enum.campaignStateType.paused": "Paused",
  "enum.campaignStateType.ended": "Ended",
  "enum.campaignStateType.scheduled": "Scheduled",
  "enum.campaignStateType.live": "Live",

  "enum.role.admin": "Admin",
  "enum.role.user": "User",
  "enum.role.prospectUser": "ProspectUser",
  "enum.role.tenantManager": "TenantManager",
  "enum.role.apiKeyUser": "ApiKeyUser",

  "enum.userStatus.active": "Active",
  "enum.userStatus.inactive": "Inactive",

  "enum.status.notOpened": "Not opened",
  "enum.status.opened": "Opened",
  "enum.status.ordered": "Ordered",

  "enum.endDateSetting.none": "None",
  "enum.endDateSetting.monday": "Monday",
  "enum.endDateSetting.tuesday": "Tuesday",
  "enum.endDateSetting.wednesday": "Wednesday",
  "enum.endDateSetting.thursday": "Thursday",
  "enum.endDateSetting.friday": "Friday",
  "enum.endDateSetting.saturday": "Saturday",
  "enum.endDateSetting.sunday": "Sunday",

  "enum.adAccountStrategy.group": "Group",
  "enum.adAccountStrategy.client": "Client",
  "enum.adAccountStrategy.tenant": "Tenant",

  "enum.groupMediaLibrarySettings.everythingAvailable": "Everything Available",
  "enum.groupMediaLibrarySettings.onlyClientOrHqWhenAvailable": "Only Client Or Hq When Available",

  "enum.emailType.orderCampaignCreate": "Order Campaign Create",
  "enum.emailType.orderCampaignExtend": "Order Campaign Extend",
  "enum.emailType.campaignLive": "Campaign Live",
  "enum.emailType.campaignEnd": "Campaign Ended",
  "enum.creationSourceType.manual": "Manual",
  "enum.creationSourceType.prospecting": "Prospecting",
  "enum.creationSourceType.prospectingAutomation": "Automated Prospecting",
  "enum.creationSourceType.integration": "Integration",
  "enum.creationSourceType.api": "Partner API",
  "enum.creationSourceType.draftTemplate": "Draft Template",
  "enum.creationSourceType.batch": "Batch",

  "enum.profileAccess.none": "None",
  "enum.profileAccess.pending": "Pending",
  "enum.profileAccess.approved": "Approved",
  "enum.profileAccess.error": "Error",

  "adsLastUpdated.all": "All",
  "adsLastUpdated.oneDay": "> 1 day",
  "adsLastUpdated.twoDays": "> 2 days",
  "adsLastUpdated.threeDays": "> 3 days",
  "adsLastUpdated.fourDays": "> 4 days",
  "adsLastUpdated.fiveDays": "> 5 days",
  "adsLastUpdated.sixDays": "> 6 days",
  "adsLastUpdated.oneWeek": "> 1 week",
  "adsLastUpdated.twoWeeks": "> 2 weeks",
  "adsLastUpdated.threeWeeks": "> 3 weeks",

  "crawlSpeed.00:15:00": "15 Minutes",
  "crawlSpeed.00:30:00": "30 Minutes",
  "crawlSpeed.00:45:00": "45 Minutes",
  "crawlSpeed.01:00:00": "1 Hour",
  "crawlSpeed.03:00:00": "3 Hours",
  "crawlSpeed.06:00:00": "6 Hours",
  "crawlSpeed.12:00:00": "12 Hours",
  "crawlSpeed.1.00:00:00": "1 Day",
  "crawlSpeed.3.00:00:00": "3 Days",
  "crawlSpeed.7.00:00:00": "7 Days",

  "time.seconds": " Seconds",
  "time.minutes": " Minutes",
  "time.hours": " Hours",
  "time.days": " Days",

  "weekdays.monday": "Monday",
  "weekdays.tuesday": "Tuesday",
  "weekdays.wednesday": "Wednesday",
  "weekdays.thursday": "Thursday",
  "weekdays.friday": "Friday",
  "weekdays.saturday": "Saturday",
  "weekdays.sunday": "Sunday",

  "dataSourceProviderTemplateFilter.combine": "combine",
  "dataSourceProviderTemplateFilter.reset": "reset",

  "recipientMap.title": "Mappings for Recipients",
  "recipientMap.addRecipientMap": "Add Mapping",
  "recipientMap.override": "Override",
  "recipientMap.reference.error": "Reference cannot be empty.",
  "recipientMap.override.error": "Override cannot be empty, and must be valid if it's an email address.",

  "enum.ascending": "Ascending",
  "enum.descending": "Descending",

  "billingType.unspecified": "Unspecified",
  "billingType.monthly": "Monthly in advance",
  "billingType.monthlyArrears": "Monthly in arrears",
  "billingType.quarterly": "Quarterly in advance",
  "billingType.semiYearly": "Semi-annually in advance",
  "billingType.yearly": "Annually in advance",
  "billingType.onDemand": "When ordering in the webshop",
  "billingType.external": "External",
  "cancelReason.duplicate": "Duplicate",
  "cancelReason.regretted": "Regretted",
  "cancelReason.incorrect": "Incorrect",

  "videoFormats.square": "Square",
  "videoFormats.portrait9x16": "Portrait9x16",
  "videoFormats.landscape16x9": "Landscape16x9",
  "videoFormats.vertical": "Vertical",
  "videoFormats.all": "All",
  "videoFormats.none": "None",

  "sampleEmailType.prospectFound": "Prospect Found",
  "sampleEmailType.prospectOrdered": "Prospect Ordered",
  "sampleEmailType.prospectExtended": "Prospect Extended",
  "sampleEmailType.prospectCampaignLive": "Prospect Campaign Live",
  "sampleEmailType.prospectCampaignEnded": "Prospect Campaign Ended",
  "sampleEmailType.scheduledReport": "ScheduledReport",
  "sampleEmailType.sendDraft": "GA Draft",

  "adCategory.IAB1": "Arts/Ent",
  "adCategory.IAB10": "Home",
  "adCategory.IAB10-1": "Appliances",
  "adCategory.IAB10-2": "Entertaining",
  "adCategory.IAB10-3": "Environmental",
  "adCategory.IAB10-4": "Gardening",
  "adCategory.IAB10-5": "Home",
  "adCategory.IAB10-6": "Home",
  "adCategory.IAB10-7": "Interior",
  "adCategory.IAB10-8": "Landscaping",
  "adCategory.IAB10-9": "Remodeling",
  "adCategory.IAB11": "Law,",
  "adCategory.IAB1-1": "Books",
  "adCategory.IAB11-2": "Legal",
  "adCategory.IAB11-4": "Politics (S)",
  "adCategory.IAB12": "News",
  "adCategory.IAB1-2": "Celebrity",
  "adCategory.IAB12-1": "International",
  "adCategory.IAB12-2": "National",
  "adCategory.IAB12-3": "Local",
  "adCategory.IAB1-3": "Fine",
  "adCategory.IAB13": "Personal (S)",
  "adCategory.IAB13-1": "Beginning (S)",
  "adCategory.IAB13-10": "Retirement (S)",
  "adCategory.IAB13-11": "Stocks (S)",
  "adCategory.IAB13-12": "Tax (S)",
  "adCategory.IAB13-2": "Credit/Debt (S)",
  "adCategory.IAB13-3": "Financial (S)",
  "adCategory.IAB13-4": "Financial (S)",
  "adCategory.IAB13-5": "Hedge (S)",
  "adCategory.IAB13-6": "Insurance (S)",
  "adCategory.IAB13-7": "Investing (S)",
  "adCategory.IAB13-8": "Mutual (S)",
  "adCategory.IAB13-9": "Options (S)",
  "adCategory.IAB14": "Society",
  "adCategory.IAB1-4": "Humor",
  "adCategory.IAB14-1": "Dating",
  "adCategory.IAB14-2": "Divorce",
  "adCategory.IAB14-4": "Marriage",
  "adCategory.IAB14-5": "Senior",
  "adCategory.IAB14-6": "Teens",
  "adCategory.IAB14-7": "Weddings",
  "adCategory.IAB15": "Science",
  "adCategory.IAB1-5": "Movies",
  "adCategory.IAB15-10": "Weather",
  "adCategory.IAB15-2": "Biology",
  "adCategory.IAB15-3": "Chemistry",
  "adCategory.IAB15-4": "Geology",
  "adCategory.IAB15-6": "Physics",
  "adCategory.IAB15-7": "Space/Astronomy",
  "adCategory.IAB15-8": "Geography",
  "adCategory.IAB15-9": "Botany",
  "adCategory.IAB16": "Pets",
  "adCategory.IAB1-6": "Music",
  "adCategory.IAB16-1": "Aquariums",
  "adCategory.IAB16-2": "Birds",
  "adCategory.IAB16-3": "Cats",
  "adCategory.IAB16-4": "Dogs",
  "adCategory.IAB16-5": "Large",
  "adCategory.IAB16-6": "Reptiles",
  "adCategory.IAB16-7": "Veterinary",
  "adCategory.IAB17": "Sports",
  "adCategory.IAB1-7": "Television",
  "adCategory.IAB17-1": "Auto",
  "adCategory.IAB17-10": "Figure",
  "adCategory.IAB17-11": "Fly",
  "adCategory.IAB17-12": "Football",
  "adCategory.IAB17-13": "Freshwater",
  "adCategory.IAB17-14": "Game",
  "adCategory.IAB17-15": "Golf",
  "adCategory.IAB17-16": "Horse",
  "adCategory.IAB17-17": "Horses",
  "adCategory.IAB17-18": "Hunting/Shooting",
  "adCategory.IAB17-19": "Inline",
  "adCategory.IAB17-2": "Baseball",
  "adCategory.IAB17-20": "Martial",
  "adCategory.IAB17-21": "Mountain",
  "adCategory.IAB17-23": "Olympics",
  "adCategory.IAB17-24": "Paintball",
  "adCategory.IAB17-25": "Power",
  "adCategory.IAB17-26": "Pro",
  "adCategory.IAB17-27": "Pro",
  "adCategory.IAB17-28": "Rodeo",
  "adCategory.IAB17-29": "Rugby",
  "adCategory.IAB17-3": "Bicycling",
  "adCategory.IAB17-30": "Running/Jogging",
  "adCategory.IAB17-31": "Sailing",
  "adCategory.IAB17-32": "Saltwater",
  "adCategory.IAB17-33": "Scuba",
  "adCategory.IAB17-34": "Skateboarding",
  "adCategory.IAB17-35": "Skiing",
  "adCategory.IAB17-36": "Snowboarding",
  "adCategory.IAB17-37": "Surfing/Bodyboarding",
  "adCategory.IAB17-38": "Swimming",
  "adCategory.IAB17-39": "Table",
  "adCategory.IAB17-4": "Bodybuilding",
  "adCategory.IAB17-40": "Tennis",
  "adCategory.IAB17-41": "Volleyball",
  "adCategory.IAB17-42": "Walking",
  "adCategory.IAB17-43": "Waterski/Wakeboard",
  "adCategory.IAB17-44": "World",
  "adCategory.IAB17-5": "Boxing",
  "adCategory.IAB17-6": "Canoeing/Kayaking",
  "adCategory.IAB17-7": "Cheerleading",
  "adCategory.IAB17-8": "Climbing",
  "adCategory.IAB17-9": "Cricket",
  "adCategory.IAB18": "Style",
  "adCategory.IAB18-1": "Beauty",
  "adCategory.IAB18-2": "Body",
  "adCategory.IAB18-3": "Fashion",
  "adCategory.IAB18-4": "Jewelry",
  "adCategory.IAB18-5": "Clothing",
  "adCategory.IAB18-6": "Accessories",
  "adCategory.IAB19": "Technology",
  "adCategory.IAB19-5": "Cameras",
  "adCategory.IAB19-6": "Cell",
  "adCategory.IAB2": "Automotive (S)",
  "adCategory.IAB20": "Travel",
  "adCategory.IAB20-1": "Adventure",
  "adCategory.IAB20-10": "Canada",
  "adCategory.IAB20-11": "Caribbean",
  "adCategory.IAB20-12": "Cruises",
  "adCategory.IAB20-13": "Eastern",
  "adCategory.IAB20-14": "Europe",
  "adCategory.IAB20-15": "France",
  "adCategory.IAB20-16": "Greece",
  "adCategory.IAB20-17": "Honeymoons/Getaways",
  "adCategory.IAB20-18": "Hotels",
  "adCategory.IAB20-19": "Italy",
  "adCategory.IAB20-2": "Africa",
  "adCategory.IAB20-20": "Japan",
  "adCategory.IAB20-21": "Mexico",
  "adCategory.IAB20-22": "National",
  "adCategory.IAB20-23": "South",
  "adCategory.IAB20-24": "Spas",
  "adCategory.IAB20-25": "Theme",
  "adCategory.IAB20-26": "Traveling",
  "adCategory.IAB20-27": "United",
  "adCategory.IAB20-3": "Air",
  "adCategory.IAB20-4": "Australia",
  "adCategory.IAB20-5": "Bed",
  "adCategory.IAB20-6": "Budget",
  "adCategory.IAB20-7": "Business",
  "adCategory.IAB20-8": "By",
  "adCategory.IAB20-9": "Camping",
  "adCategory.IAB21": "Real-Estate (S)",
  "adCategory.IAB21-1": "Apartments (S)",
  "adCategory.IAB21-2": "Architects (S)",
  "adCategory.IAB21-3": "Buying/Selling (S)",
  "adCategory.IAB2-1": "Auto",
  "adCategory.IAB2-10": "Electric",
  "adCategory.IAB2-11": "Hatchback",
  "adCategory.IAB2-12": "Hybrid",
  "adCategory.IAB2-13": "Luxury",
  "adCategory.IAB2-14": "MiniVan",
  "adCategory.IAB2-15": "Mororcycles",
  "adCategory.IAB2-16": "Off-Road",
  "adCategory.IAB2-17": "Performance",
  "adCategory.IAB2-18": "Pickup",
  "adCategory.IAB2-19": "Road-Side",
  "adCategory.IAB22": "Shopping",
  "adCategory.IAB2-2": "Auto",
  "adCategory.IAB2-20": "Sedan",
  "adCategory.IAB2-21": "Trucks",
  "adCategory.IAB22-1": "Contests",
  "adCategory.IAB2-22": "Vintage",
  "adCategory.IAB22-2": "Couponing",
  "adCategory.IAB2-23": "Wagon",
  "adCategory.IAB22-4": "Engines",
  "adCategory.IAB23": "Religion",
  "adCategory.IAB2-3": "Buying/Selling (S)",
  "adCategory.IAB23-1": "Alternative",
  "adCategory.IAB23-10": "Pagan/Wiccan",
  "adCategory.IAB23-2": "Atheism/Agnosticism",
  "adCategory.IAB23-3": "Buddhism",
  "adCategory.IAB23-4": "Catholicism",
  "adCategory.IAB23-5": "Christianity",
  "adCategory.IAB23-6": "Hinduism",
  "adCategory.IAB23-7": "Islam",
  "adCategory.IAB23-8": "Judaism",
  "adCategory.IAB2-4": "Car",
  "adCategory.IAB2-5": "Certified",
  "adCategory.IAB2-6": "Convertible",
  "adCategory.IAB2-7": "Coupe",
  "adCategory.IAB2-8": "Crossover",
  "adCategory.IAB2-9": "Diesel",
  "adCategory.IAB3": "Business",
  "adCategory.IAB3-1": "Advertising",
  "adCategory.IAB3-10": "Logistics",
  "adCategory.IAB3-11": "Marketing",
  "adCategory.IAB3-12": "Metals",
  "adCategory.IAB3-2": "Agriculture",
  "adCategory.IAB3-3": "Biotech/Biomedical",
  "adCategory.IAB3-4": "Business",
  "adCategory.IAB3-5": "Construction",
  "adCategory.IAB3-6": "Forestry",
  "adCategory.IAB3-7": "Government",
  "adCategory.IAB3-8": "Green",
  "adCategory.IAB3-9": "Human",
  "adCategory.IAB4": "Careers (S)",
  "adCategory.IAB4-1": "Career (S)",
  "adCategory.IAB4-10": "U.S. (S)",
  "adCategory.IAB4-11": "Career (S)",
  "adCategory.IAB4-2": "College (S)",
  "adCategory.IAB4-3": "Financial (S)",
  "adCategory.IAB4-4": "Job (S)",
  "adCategory.IAB4-5": "Job (S)",
  "adCategory.IAB4-6": "Resume (S)",
  "adCategory.IAB4-7": "Nursing (S)",
  "adCategory.IAB4-8": "Scholarships (S)",
  "adCategory.IAB5": "Education",
  "adCategory.IAB5-1": "7-12",
  "adCategory.IAB5-10": "Homeschooling",
  "adCategory.IAB5-11": "Homework/Study",
  "adCategory.IAB5-12": "K-6",
  "adCategory.IAB5-13": "Private",
  "adCategory.IAB5-14": "Special",
  "adCategory.IAB5-15": "Studying",
  "adCategory.IAB5-2": "Adult",
  "adCategory.IAB5-3": "Art",
  "adCategory.IAB5-4": "College",
  "adCategory.IAB5-5": "College",
  "adCategory.IAB5-6": "Distance",
  "adCategory.IAB5-7": "ESL",
  "adCategory.IAB5-8": "Language",
  "adCategory.IAB5-9": "Graduate",
  "adCategory.IAB6": "Family",
  "adCategory.IAB6-1": "Adoption",
  "adCategory.IAB6-2": "Babies",
  "adCategory.IAB6-3": "Daycare/Pre",
  "adCategory.IAB6-4": "Family",
  "adCategory.IAB6-5": "Parenting",
  "adCategory.IAB6-6": "Parenting",
  "adCategory.IAB6-7": "Pregnancy",
  "adCategory.IAB6-8": "Special",
  "adCategory.IAB6-9": "Eldercare",
  "adCategory.IAB7": "Health",
  "adCategory.IAB7-1": "Exercise",
  "adCategory.IAB7-10": "Brain",
  "adCategory.IAB7-11": "Cancer",
  "adCategory.IAB7-12": "Cholesterol",
  "adCategory.IAB7-13": "Chronic",
  "adCategory.IAB7-14": "Chronic",
  "adCategory.IAB7-15": "Cold/Flu",
  "adCategory.IAB7-16": "Deafness",
  "adCategory.IAB7-17": "Dental",
  "adCategory.IAB7-18": "Depression",
  "adCategory.IAB7-19": "Dermatology",
  "adCategory.IAB7-2": "A.D.D.",
  "adCategory.IAB7-20": "Diabetes",
  "adCategory.IAB7-21": "Epilepsy",
  "adCategory.IAB7-22": "GERD/Acid",
  "adCategory.IAB7-23": "Headaches/Migraines",
  "adCategory.IAB7-24": "Heart",
  "adCategory.IAB7-27": "IBS/Crohn's",
  "adCategory.IAB7-29": "Incontinence",
  "adCategory.IAB7-3": "AIDS/HIV",
  "adCategory.IAB7-30": "Infertility",
  "adCategory.IAB7-31": "Men's",
  "adCategory.IAB7-32": "Nutrition",
  "adCategory.IAB7-33": "Orthopedics",
  "adCategory.IAB7-34": "Panic/Anxiety",
  "adCategory.IAB7-35": "Pediatrics",
  "adCategory.IAB7-36": "Physical",
  "adCategory.IAB7-37": "Psychology/Psychiatry",
  "adCategory.IAB7-38": "Senor",
  "adCategory.IAB7-4": "Allergies",
  "adCategory.IAB7-40": "Sleep",
  "adCategory.IAB7-41": "Smoking",
  "adCategory.IAB7-42": "Substance",
  "adCategory.IAB7-43": "Thyroid",
  "adCategory.IAB7-44": "Weight",
  "adCategory.IAB7-45": "Women's",
  "adCategory.IAB7-6": "Arthritis",
  "adCategory.IAB7-7": "Asthma",
  "adCategory.IAB7-8": "Autism/PDD",
  "adCategory.IAB7-9": "Bipolar",
  "adCategory.IAB8": "Food",
  "adCategory.IAB8-1": "American",
  "adCategory.IAB8-10": "Food",
  "adCategory.IAB8-11": "French",
  "adCategory.IAB8-12": "Health/Lowfat",
  "adCategory.IAB8-13": "Italian",
  "adCategory.IAB8-14": "Japanese",
  "adCategory.IAB8-15": "Mexican",
  "adCategory.IAB8-16": "Vegan",
  "adCategory.IAB8-17": "Vegetarian",
  "adCategory.IAB8-18": "Wine",
  "adCategory.IAB8-2": "Barbecues",
  "adCategory.IAB8-3": "Cajun/Creole",
  "adCategory.IAB8-4": "Chinese",
  "adCategory.IAB8-5": "Cocktails/Beer",
  "adCategory.IAB8-6": "Coffee/Tea",
  "adCategory.IAB8-7": "Cuisine-Specific",
  "adCategory.IAB8-8": "Desserts/Baking",
  "adCategory.IAB8-9": "Dining",
  "adCategory.IAB9": "Hobbies",
  "adCategory.IAB9-1": "Art/Technology",
  "adCategory.IAB9-10": "Collecting",
  "adCategory.IAB9-11": "Comic",
  "adCategory.IAB9-12": "Drawing/Sketching",
  "adCategory.IAB9-13": "Freelance",
  "adCategory.IAB9-14": "Genealogy",
  "adCategory.IAB9-15": "Getting",
  "adCategory.IAB9-16": "Guitar",
  "adCategory.IAB9-17": "Home",
  "adCategory.IAB9-18": "Investors",
  "adCategory.IAB9-19": "Jewelry",
  "adCategory.IAB9-2": "Arts",
  "adCategory.IAB9-20": "Magic",
  "adCategory.IAB9-21": "Needlework",
  "adCategory.IAB9-22": "Painting",
  "adCategory.IAB9-23": "Photography",
  "adCategory.IAB9-24": "Radio",
  "adCategory.IAB9-25": "Roleplaying",
  "adCategory.IAB9-26": "Sci-Fi/Fantasy",
  "adCategory.IAB9-27": "Scrapbooking",
  "adCategory.IAB9-28": "Screenwriting",
  "adCategory.IAB9-29": "Stamps",
  "adCategory.IAB9-3": "Beadwork",
  "adCategory.IAB9-30": "Video",
  "adCategory.IAB9-31": "Woodworking",
  "adCategory.IAB9-4": "Birdwatching",
  "adCategory.IAB9-5": "Board",
  "adCategory.IAB9-6": "Candle",
  "adCategory.IAB9-7": "Card",
  "adCategory.IAB9-8": "Chess",
  "adCategory.IAB9-9": "Cigars",
}